:root {
  font-size: 18px;
}
.App {
  text-align: center;
}


body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
