@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
.App {
  text-align: center;
  background-image:linear-gradient(rgba(0, 0, 0, 0.8),
  rgba(0, 0, 0, 0.8)), url(./assets/background.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;
}

header {
  
}

.HeaderTitle {
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 900;
}

.titleCtr {
  display: flex;
  justify-content: center;
}


.main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
}

.mintTop {
  padding-top: 1rem;
}

.mintLogo {
  border-radius: 50%;
  padding: 1rem;
}

.countContainer{
  justify-content:center;
  text-align: center;
  align-items: center;
  display: flex;
}

.mintAmount {
  font-size: 2rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
  margin: 0.9rem;
  font-weight: 800;
}

.mathButton {
  font-size: 1.5em;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 12px;
  margin: 1.5rem;
  font-weight: 900;
  padding: 1rem;
  color: white;
  background-color: rgba(247, 0, 33, 0.5);
  font-family: 'Montserrat', sans-serif;
}

.mintTitle{
  font-size: 1em;
  color: #fff;
  font-weight: bold;
}

.mintdescription {
  font-size: 1.25rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color: rgb(255, 255, 255);
  padding: .3rem;
}

.mintButton {
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  width: 12rem;
  min-height: 3rem;
  border-radius: 12px;
  background-color: rgba(247, 0, 33, 0.5);
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 2rem;
}
